<template>
    <label @click="input.click()" class="btn">
        <slot name="button">
            <i class="iconfont iconadd-circle"></i>
        </slot>
    </label>
</template>

<script>

import Axios from '@/axios'
export default {
    props: {
        accept:{
            type:String,
            default:'image/*'
        },
        multiple:{
            type:Boolean,
            default:false
        },
        change: {
            type: Function,
            default: () => {}
        },
    },
    components: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    mounted() {
        this.input = document.createElement('input');
        this.input.setAttribute("type", "file");
        if(this.multiple){
            this.input.setAttribute("multiple", "multiple");
        };
        if(this.accept){
            this.input.setAttribute("accept", this.accept);
        };
        this.input.addEventListener('change', e => {
            var blobData = [];
            var fileData = [];
            for (let i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i]
                blobData.push({
                    name:file.name,
                    data:new Blob([file])
                })
            }
            blobData.some(r=>{
                fileData.push(new File([r.data],r.name))
            })
            this.change({
                value:e.target.value,
                files:fileData,
            })
            this.input.value = ''
        });
    },
    methods: {

    }
};
</script>

<style lang="scss" scoped>
.btn{
    /deep/.el-input {
        .el-input__inner {
            background: none;
            cursor: pointer;
        }
    }
}
</style>
